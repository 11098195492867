<template>
  <div
    class="home"
    :style="
      showPrivacyPolicy || showTerms || popup
        ? { filter: 'blur(30px)' }
        : { filter: 'none' }
    "
  >
    <video autoplay muted loop playsinline id="myVideo">
      <source
        src="https://asset-signax.b-cdn.net/pexels-louai-munajim-5818973%20(2).mp4"
      />
    </video>
    <div class="home-container">
      <AppBar :menu="menu" />
      <Landing />
      <About />
      <BannerLayout />
      <!-- <KeyChallenges /> -->
      <!-- <Vertical /> -->
      <!-- <Marketplace /> -->
      <!-- <WhoIsItForNew /> -->
      <Team />
    <SupportSection />
      <GetInTouch />
      <div class="footer" v-if="!isMobileView">
        <img src="../assets/logo.svg" />
        <div class="appbar-menu">
          <!-- <a href="https://twitter.com/signax_io" target="_blank"><img src="../assets/social/1.svg" width="20"
              class="img" /></a> -->
          <a href="https://www.linkedin.com/company/signa-x" target="_blank"
            ><img src="../assets/social/3.svg" width="22" class="img"
          /></a>
          <img src="../assets/social/4.svg" width="25" class="img" />
          <img
            src="../assets/social/6.svg"
            width="25"
            class="img"
            @click="openPopup()"
          />
        </div>
        <p @click="showTerms = true" class="data">Terms of Use</p>
        <p @click="showPrivacyPolicy = true" class="data">Privacy Policy</p>
        <h2 class="action">© 2024 - Signa X</h2>
      </div>
      <div class="footer" v-else>
        <div class="footer-div">
          <img src="../assets/logo.svg" width="40%" />
          <div class="appbar-menu mt-5">
            <a href="https://www.linkedin.com/company/signa-x" target="_blank"
              ><img src="../assets/social/3.svg" width="22" class="img"
            /></a>
            <img src="../assets/social/4.svg" width="25" class="img" />
            <img
              src="../assets/social/6.svg"
              width="25"
              class="img"
              @click="openPopup()"
            />
          </div>
          <div class="action-footer">
            <p @click="showTerms = true" class="data">Terms of Use</p>
            <p class="action">© 2024 - Signa X</p>
            <p @click="showPrivacyPolicy = true" class="data">Privacy Policy</p>
          </div>
        </div>
        <!-- <v-row style="width: 100%; align-items: flex-start;padding-top: 12px;">
          <v-col lg="5">
            <img src="../assets/logo.svg" width="80%" />
          </v-col>
          <v-col lg="6">
            <p @click="showTerms = true" class="data">Terms of Use</p>
            <p @click="showPrivacyPolicy = true" class="data">Privacy Policy</p>
          </v-col>
        </v-row>
        <v-row style="width: 100%; align-items: center;">
          <v-col lg="6">
            <div class="appbar-menu">
             <a href="https://twitter.com/signax_io" target="_blank"><img src="../assets/social/1.svg" width="20"
                  class="img" /></a> 
              <a href="https://www.linkedin.com/company/signa-x" target="_blank"><img src="../assets/social/3.svg"
                  width="22" class="img" /></a>
              <img src="../assets/social/4.svg" width="25" class="img" />
              <img src="../assets/social/6.svg" width="25" class="img" @click="openPopup()" />
            </div>
          </v-col>
          <v-col lg="6">
            <h2 class="action">© 2022 - Signa X</h2>
          </v-col>
        </v-row> -->
      </div>
    </div>
    <PrivacyPolicy :visible="showPrivacyPolicy" @close="close"></PrivacyPolicy>
    <TermsUse :visible="showTerms" @close="close"></TermsUse>
    <Register :visible="popup" @close="close"></Register>
  </div>
</template>

<script>
import Landing from "./Landing.vue";
import AppBar from "../components/AppBar.vue";
import { defineAsyncComponent } from "vue";
import PrivacyPolicy from "../components/Modal/PrivacyPolicy.vue";
import TermsUse from "../components/Modal/TermsUse.vue";
import Register from "../components/Modal/Register.vue";

export default {
  name: "Home",
  components: {
    Landing,
    About: () => import("./About.vue"),
    // KeyChallenges: defineAsyncComponent(() => import("./KeyChallenges.vue")),
    // Vertical: defineAsyncComponent(() => import("./Vertical.vue")),
    // Marketplace: defineAsyncComponent(() => import("./Marketplace.vue")),
    Team: defineAsyncComponent(() => import("./Team.vue")),
    SupportSection: defineAsyncComponent(() => import("./SupportSection.vue")),
    GetInTouch: defineAsyncComponent(() => import("./GetInTouch.vue")),
    BannerLayout: defineAsyncComponent(() => import("./Banner.vue")),
    // WhoIsItForNew: defineAsyncComponent(() => import("./WhoIsItForNew.vue")),
    AppBar,
    PrivacyPolicy,
    TermsUse,
    Register,
  },
  data() {
    return {
      menu: [
        {
          title: "About Us",
          location: "about",
        },
        {
          title: "Team",
          location: "team",
        },
        {
          title: "Contact Us",
          location: "contact",
        },
      ],
      selectedIndex: 0,
      showPrivacyPolicy: false,
      showTerms: false,
      popup: false,
    };
  },
  methods: {
    close() {
      this.showPrivacyPolicy = false;
      this.showTerms = false;
      this.popup = false;
    },
    openPopup() {
      this.popup = true;
    },
    checkPopup() {
      const path = this.$router.currentRoute;
      if (path.hash.length > 0) {
        if (path.hash === "#register") {
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 3000);
        }
      }
    },
  },
  computed: {
    isMobileView: function () {
      return window.innerWidth <= 760 ? true : false;
    },
  },
  created() {
    this.checkPopup();
  },
};
</script>
<style scoped>
.footer {
  width: 100vw;
  min-height: 50px;
  background-color: var(--appbar-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding-left: 65px;
  padding-right: 65px; */
  overflow-y: hidden;
}

.footer > img {
  width: 10%;
  height: 100%;
  object-fit: contain;
}

.action {
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: white;
  margin: 0px;
}

.appbar-menu {
  flex: 0.12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.appbar-menu > a {
  cursor: pointer;
}

.img {
  cursor: pointer;
}

.header {
  background: linear-gradient(90.45deg, #00ffbf -2.93%, #1657dc 95.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif !important;
}

.data {
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  font-family: "Chakra Petch", sans-serif;
}

@media only screen and (max-width: 600px) {
  .footer {
    width: 100vw;
    height: auto;
    background-color: var(--appbar-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    overflow-y: hidden;
    padding-bottom: 5%;
  }

  .appbar-brand {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .appbar-brand > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .appbar-action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .appbar-action > h2 {
    font-family: "Orbitron", sans-serif;
    font-weight: 400;
    font-size: 10px;
    color: white;
  }

  .appbar-menu {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  .img {
    cursor: pointer;
    width: 20px;
  }

  .header {
    background: linear-gradient(90.45deg, #00ffbf -2.93%, #1657dc 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Orbitron", sans-serif !important;
  }

  .details {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .details > p {
    color: white;
    font-size: 10px;
    cursor: pointer;
  }

  .action {
    font-family: "Orbitron", sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: white;
    margin: 0px;
  }
  .footer-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .action-footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .appbar-action {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.home-container {
  width: 100vw;
  position: relative;
}

#myVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
