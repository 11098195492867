<template>
    <div>
        <v-dialog transition="dialog-bottom-transition" persistent v-model="visible" class="dialog" max-width="400">
            <div class="card-body dialog">
                <div class="card-header">
                    <v-row align="center">
                        <v-col lg="10" cols="10">
                            <h3 class="header title">Register</h3>
                        </v-col>
                        <v-col lg="2" class="mt-1" cols="2">
                            <v-icon color="white" class="span" @click="close()" size="30">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </div>
                <div class="card-content">
                    <div class="form-group">
                        <p>Name*</p>
                        <input v-model="formData.userName" type="text" />
                    </div>
                    <!-- <div class="form-group">
                        <p>You are ?*</p>
                        <select v-model="formData.role">
                            <option value="3d_creator">3D Creator</option>
                            <option value="metaverse_project">Mataverse Project</option>
                            <option value="gaming_guilds">Gaming Guild</option>
                            <option value="enterprises_or_brands">Enterprises or Brands</option>
                            <option value="others">Others</option>
                        </select>
                    </div> -->
                    <div class="form-group">
                        <p>Email Address*</p>
                        <input v-model="formData.emailAddress" type="email" />
                    </div>
                    <div class="form-group">
                        <p>Phone Number*</p>
                        <input v-model="formData.phone" type="number" />
                    </div>
                    <div class="form-group">
                        <p>Share Details (Portfolio Link or Project Website)</p>
                        <input v-model="formData.details" type="text" />
                    </div>
                    <div class="form-group">
                        <p>Message</p>
                        <textarea v-model="formData.message" rows="2"></textarea>
                    </div>
                    <v-row>
                        <v-col lg="4"></v-col>
                        <v-col lg="4">
                            <div class="button-container">
                                <button class="button btn" @click="submit()">Submit</button>
                                <div class="elipse-one"></div>
                                <div class="elipse-two"></div>
                            </div>
                        </v-col>
                        <v-col lg="4"></v-col>
                    </v-row>
                </div>
            </div>
        </v-dialog>
        <StatusModal :visible="showPopup" :status="isSuccess" :title="statusTitle" :message="statusMessage">
        </StatusModal>
        <Loader :visible="isLoading"></Loader>
    </div>
</template>
<script>
import { mailServiceObj } from '../../services/mail-service';
import { registerObj } from '../../services/register-service'
import StatusModal from './StatusModal.vue'
import Loader from './Loader.vue';

export default {
    name: "Register",
    props: ["visible", "data"],
    data() {
        return {
            formData: {
                role: "",
                userName: "",
                emailAddress: "",
                message: "",
                phone: "",
                details: "",
            },
            isLoading: false,
            loaderText: "",
            showPopup: false,
            statusTitle: "",
            statusMessage: "",
            isSuccess: false
        };
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async submit() {
            try {
                if (this.formData.userName === "" || this.formData.emailAddress === "" || this.formData.phone === "") {
                    this.isSuccess = false;
                    this.statusTitle = "Fill the form";
                    this.statusMessage = 'Fill all the marked data';
                    this.showPopup = true;
                    setTimeout(() => {
                        this.showPopup = false;
                    }, 2000);
                    return;
                }
                this.loaderText = "Submitting Request...";
                this.isLoading = true;
                await registerObj.create(this.formData);
                await mailServiceObj.sendNotificationEmailToSignax();
                this.isLoading = false;
                this.isSuccess = true;
                this.statusTitle = "Submission Successful";
                this.statusMessage = "Your request has been received. We will get back to you shortly";
                this.showPopup = true;
                setTimeout(() => {
                    this.showPopup = false;
                    this.close();
                }, 4000);
            }
            catch (error) {
                this.isLoading = false;
                this.isSuccess = false;
                this.statusTitle = "Submission Failed";
                this.statusMessage = error.message;
                this.showPopup = true;
                setTimeout(() => {
                    this.showPopup = false;
                }, 5000);
            }
        }
    },
    components: { StatusModal,Loader }
}
</script>
<style scoped>
.dialog {
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    overflow: hidden;
}

.form-group {
    width: 100%;
    margin-top: 20px;
}

.form-group>p {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Chakra Petch', sans-serif;
    color: #E0E0E0;
    margin: 0px;
}

.form-group>input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid #00FFBF;
    outline: none;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 12px;
    padding: 5px;
}

.form-group>textarea {
    width: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid #00FFBF;
    outline: none;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 12px;
    padding: 5px;
}

.form-group>select {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.16);
    border: 1px solid #00FFBF;
    outline: none;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 12px;
    padding: 5px;
}

option {
    background-color: var(--primary-color);
    font-size: 14px;
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.title {
    font-size: 30px !important;
    text-align: left;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 600 !important;
}

.btn {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
}

.button-container {
    width: 100%;
    height: 40px;
    position: relative;
    margin-top: 10px;
}

.elipse-one {
    width: 30px;
    height: 30px;
    background: #4629C6;
    filter: blur(23px);
    position: absolute;
    top: 0;
    left: 10px;
}

.elipse-two {
    background: #00F2F6;
    filter: blur(23px);
    width: 20px;
    height: 20px;
    bottom: -14px;
    right: 0;
    position: absolute;
}
</style>