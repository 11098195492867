var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-container"},[_c('div',{staticClass:"landing-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"button-container"},[_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: `#about`,
                container: 'body',
                duration: 500,
                lazy: false,
                easing: 'linear',
                offset: 0,
                force: true,
                cancelable: true,
                x: false,
                y: true,
            }),expression:"{\n                el: `#about`,\n                container: 'body',\n                duration: 500,\n                lazy: false,\n                easing: 'linear',\n                offset: 0,\n                force: true,\n                cancelable: true,\n                x: false,\n                y: true,\n            }"}],staticClass:"button btn"},[_vm._v("Explore "),_c('img',{staticClass:"arrow",staticStyle:{"margin-left":"10px"},attrs:{"src":require("../assets/arrow.svg"),"width":"25px"}})]),_c('div',{staticClass:"elipse-one"}),_c('div',{staticClass:"elipse-two"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading-container"},[_c('h3',{staticClass:"header"},[_vm._v("Building Infrastructure")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-container"},[_c('p',[_vm._v("for the Next Billion Users")])])
}]

export { render, staticRenderFns }