<template>
    <div class="landing-container">
        <div class="landing-content">
            <div class="heading-container">
                <h3 class="header">Building Infrastructure</h3>
            </div>
            <div class="sub-container">
                <p>for the Next Billion Users</p>
            </div>
            <div class="button-container">
                <button class="button btn" v-scroll-to="{
                    el: `#about`,
                    container: 'body',
                    duration: 500,
                    lazy: false,
                    easing: 'linear',
                    offset: 0,
                    force: true,
                    cancelable: true,
                    x: false,
                    y: true,
                }">Explore
                    <img src="../assets/arrow.svg" width="25px" style="margin-left: 10px;" class="arrow"/>
                </button>
                <div class="elipse-one"></div>
                <div class="elipse-two"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Landing",
    computed: {
        isMobileView: function () {
            return window.innerWidth <= 760 ? true : false;
        },
    }
}
</script>
<style>
.landing-container {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    background-image: url('../assets/landing_background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background: rgba(0,0,0,0.7) !important; */
}

.landing-content {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading-container {
    width: 50%;
}

.heading-container>h3 {
    margin: 0px;
    font-weight: 800;
    font-size: 70px;
    text-align: center;
}

.heading-container>p {
    margin: 0px;
}

.sub-container>p {
    margin: 0px;
    font-family: 'Orbitron', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.btn {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.button-container {
    width: 10%;
    height: 50px;
    position: relative;
    margin-top: 40px;
}

.elipse-one {
    width: 50px;
    height: 50px;
    background: #4629C6;
    filter: blur(23px);
    position: absolute;
    top: 0;
    left: 10px;
}

.elipse-two {
    background: #00F2F6;
    filter: blur(23px);
    width: 50px;
    height: 50px;
    bottom: -14px;
    right: 0;
    position: absolute;
}

.header {
    background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
}

@media only screen and (max-width: 600px) {
    .landing-container {
        width: 100%;
        height: 100vh;
        max-width: 100%;
        background-image: url('../assets/landing_background.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .landing-content {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .heading-container {
        width: 90%;
        margin-top: 20%;
    }

    .heading-container>h3 {
        margin: 0px;
        font-weight: 800;
        font-size: 40px;
        text-align: center;
    }

    .heading-container>p {
        margin: 0px;
    }

    .sub-container>p {
        margin: 0px;
        font-family: 'Orbitron', sans-serif;
        font-size: 30px;
        font-weight: 500;
        color: white;
        text-align: center;
    }

    .btn {
        width: 100%;
        height: 100%;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-container {
        width: 40%;
        height: 50px;
        position: relative;
        margin-top: 40px;
    }

    .elipse-one {
        width: 50px;
        height: 50px;
        background: #4629C6;
        filter: blur(23px);
        position: absolute;
        top: 0;
        left: 10px;
    }

    .elipse-two {
        background: #00F2F6;
        filter: blur(23px);
        width: 50px;
        height: 50px;
        bottom: -14px;
        right: 0;
        position: absolute;
    }

    .header {
        background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'Orbitron', sans-serif !important;
    }
    .arrow{
        width: 20px !important;
    }
}

@media only screen and (min-width: 768px) {
    .button-container {
        width: 20%;
        height: 50px;
        position: relative;
        margin-top: 40px;
    }

    .heading-container {
        width: 75%;
    }
}

@media only screen and (min-width: 992px) {
    .button-container {
        width: 10%;
        height: 50px;
        position: relative;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 300px) {
    .landing-container {
        width: 100%;
        height: 100vh;
        max-width: 100%;
        background-image: url('../assets/landing_background.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .landing-content {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .heading-container {
        width: 90%;
        margin-top: 20%;
    }

    .heading-container>h3 {
        margin: 0px;
        font-weight: 800;
        font-size: 30px;
        text-align: center;
    }

    .heading-container>p {
        margin: 0px;
        font-size: 18px;
    }

    .sub-container>p {
        margin: 0px;
        font-family: 'Orbitron', sans-serif;
        font-size: 25px;
        font-weight: 500;
        color: white;
        text-align: center;
    }

    .btn {
        width: 100%;
        height: 100%;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button-container {
        width: 40%;
        height: 50px;
        position: relative;
        margin-top: 40px;
    }

    .elipse-one {
        width: 50px;
        height: 50px;
        background: #4629C6;
        filter: blur(23px);
        position: absolute;
        top: 0;
        left: 10px;
    }

    .elipse-two {
        background: #00F2F6;
        filter: blur(23px);
        width: 50px;
        height: 50px;
        bottom: -14px;
        right: 0;
        position: absolute;
    }

    .header {
        background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-family: 'Orbitron', sans-serif !important;
    }
    .arrow{
        width: 20px !important;
    }
}
</style>