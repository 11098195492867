import { initializeApp } from "firebase/app";
import {
    getFirestore,
    doc,
    setDoc,
    addDoc,
    collection,
    getDoc,
    getDocs,
    where,
    query,
    updateDoc,
    arrayUnion,
    orderBy,
    deleteDoc
} from "firebase/firestore";

const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyCEKk6cV7BRqUzDl4axxloql6wbZo1HNY8',
    authDomain: 'signax-d7668.firebaseapp.com',
    projectId: 'signax-d7668',
    storageBucket: 'signax-d7668.appspot.com',
    messagingSenderId: 834846471724,
    appId: '1:834846471724:web:aaffffeac64a0145edc389',
    measurementId: 'G-4KQEVKQK1F',
};

const firebaseApp = initializeApp(FIREBASE_CONFIG);
const firebaseDB = getFirestore(firebaseApp);

const websiteRef = collection(firebaseDB, "website_request");
const waitListRef = collection(firebaseDB, "waitlist_request");

export {
    doc,
    setDoc,
    addDoc,
    collection,
    getDoc,
    getDocs,
    where,
    query,
    updateDoc,
    arrayUnion,
    orderBy,
    deleteDoc,
    websiteRef,
    waitListRef,
  };