<template>
    <div>
        <div class="appbar-content" v-if="!isMobileView" style="height: 50px; padding: 0px 65px">
            <div class="appbar-brand">
                <img src="../assets/logo.svg" />
            </div>
            <div class="appbar-menu">
                <h3 :class="selectedIndex === index ? `${'header head-title'}` : `${'head-title-not'}`"
                    v-for="(item, index) in menu" :key="index" @click="onTabClick(index)" v-scroll-to="{
                        el: `#${item.location}`,
                        container: 'body',
                        duration: 500,
                        lazy: false,
                        easing: 'linear',
                        offset: 0,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true,
                    }">{{ item.title }}</h3>
            </div>
            <div class=" appbar-action">
                <!-- <div class="button-container">
                    <button class="button btn" @click="onRegisterClick()">Login</button>
                    <div class="elipse-one"></div>
                    <div class="elipse-two"></div>
                </div> -->
            </div> 
        </div>
        <div class="appbar-container" v-else>
            <div class="appbar-content">
                <v-row style="align-items: center;height: 50px;">
                    <v-col lg="4">
                        <img src="../assets/menu.svg" width="30px" @click="onMenuToggle()" />
                    </v-col>
                    <v-col lg="4">
                        <img src="../assets/logo.svg" style="height: 25px; object-fit: cover;" />
                    </v-col>
                    <v-col lg="4">

                    </v-col>
                </v-row>
            </div>
            <div class="show-app-menu" v-if="menuClicked">
                <h3 :class="selectedIndex === index ? `${'header head-title'}` : `${'head-title-not'}`"
                    v-for="(item, index) in menu" :key="index" @click="onTabClick(index)" v-scroll-to="{
                        el: `#${item.location}`,
                        container: 'body',
                        duration: 500,
                        lazy: false,
                        easing: 'linear',
                        offset: 0,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true,
                    }">{{ item.title }}</h3>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: "AppBar",
    data() {
        return {
            menuClicked: false,
        }
    },
    computed: {
        ...mapState({
            selectedIndex: state => state.selectedIndex,
        }),
        isMobileView: function () {
            return window.innerWidth <= 760 ? true : false;
        },
    },
    props: ['menu'],
    methods: {
        onRegisterClick() {
            window.open('https://app.signa-x.io', '_blank');
        },
        onTabClick(index) {
            console.log(index);
            setTimeout(() => {
                this.menuClicked = false;
            }, 501);
            this.$store.dispatch('setIndex', index);
        },
        onMenuToggle() {
            this.menuClicked = !this.menuClicked;
        }
    }
}
</script>
<style scoped>
.appbar-container {
    width: 100%;
    min-height: 50px;
    background-color: var(--appbar-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    position: fixed;
    z-index: 9999;
    box-shadow: 5px 5px 5px 0 #00FFBF;
}

.appbar-content {
    width: 100%;
    min-height: 8vh;
    background-color: var(--appbar-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    box-shadow: 2px 2px 5px 0 #00FFBF;
}

.appbar-brand {
    width: 10%;
    height: 100%;
}

.appbar-brand>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.appbar-menu {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}

.appbar-action {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    height: 100%;
    font-size: 14px;
    font-weight: 500;
}

.button-container {
    width: 100%;
    height: 30px;
    position: relative;
    margin-top: 0px;
}

.elipse-one {
    width: 30px;
    height: 30px;
    background: #4629C6;
    filter: blur(30px);
    position: absolute;
    top: 0;
    left: 10px;
}

.elipse-two {
    background: #00F2F6;
    filter: blur(30px);
    width: 30px;
    height: 30px;
    bottom: -14px;
    right: 0;
    position: absolute;
}

.head-title {
    cursor: pointer;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
}

.head-title-not {
    cursor: pointer;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    color: #E0E0E0 !important;
}

.show-app-menu {
    width: 100%;
    height: auto;
    background-color: var(--appbar-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
    padding: 20px;
}

.show-app-menu>h3 {
    margin: 10px 0px;
}

@media only screen and (max-width: 600px) {
    .appbar-content {
        width: 100%;
        min-height: 8vh;
        background-color: var(--appbar-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        box-shadow: none;
    }

    .appbar-container {
        width: 100%;
        min-height: 50px;
        background-color: var(--appbar-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        position: fixed;
        z-index: 9999;
        box-shadow: 1px 1px 3.5px 0 #00ffbf;
    }

}
</style>