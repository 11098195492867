<template>
    <div>
        <v-dialog v-model="visible" max-width="500" class="dialog" @click:outside="close()">
            <div class="card-body dialog">
                <v-row style="justify-content: space-between; width: 100%;">
                    <v-col lg="11" sm="11" md="11" cols="10">
                    </v-col>
                    <v-col lg="1">
                        <v-icon color="white" size="20" class="mt-2" @click="close">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="header">
                    <h3 class="header title">Privacy Policy</h3>
                </div>
                <div class="content">
                    <!-- <p class="sub-title">Last revised on 1 August 2022</p> -->
                    <p class="content-desc mt-10">Signa X (“Signa X,” “we”, “us”, or “our”) is committed to protecting your
                        privacy. We have prepared this Privacy Policy to describe to you our practices regarding the
                        Personal Data (as defined below) we collect, use, and share in connection with the Signa X
                        website, mobile app, and other software provided on or in connection with our services, as
                        described in our Terms of Service (collectively, the “Service”).</p>
                    <p class="content-heading">1. COLLECTION OF PERSONAL INFORMATION</p>
                    <p class="content-sub-heading">1.1. When you interact with us or use any service we provide, the
                        personal data we may collect includes the following:</p>
                    <div class="content-sub-content">
                        <p>I. your personal information, including but not limited to your name, email address, mobile
                            number and address;</p>
                        <p>II. information to process your order, such as your credit / debit card or your bank account
                            details;</p>
                        <p>III. information about your preferences and interests indicated in your account; and</p>
                        <p>IV. information about your use of our websites and services, including cookies, IP addresses,
                            browser type, your mobile operating system, statistics on page views and traffic to our
                            website and other diagnostic data.</p>
                    </div>
                    <p class="content-sub-heading">1.2. Generally, we collect your personal data in the following ways:
                    </p>
                    <div class="content-sub-content">
                        <p>I. when you submit forms relating to any of our products or services;</p>
                        <p>II. when you register for or use any of our services on websites owned or operated by us;</p>
                        <p>III. when you use or purchase our products or services;</p>
                        <p>IV. when you request that we contact you;</p>
                        <p>V. when you request to be included in an email or other mailing list;</p>
                        <p>VI. when you browse our website. Please see the Cookie Policy in Section 5 for more
                            information; and</p>
                        <p>VII. when you submit your personal data to us for any other reason.</p>
                    </div>
                    <p class="content-sub-heading">1.3 Minors - we do not intentionally gather Personal Data from
                        visitors who are under the age of 13. Our Terms of Service require all users to be at least 18
                        years old. Minors who are at least 13 years old but are under 18 years old may use a parent or
                        guardian’s Signa X account, but only with the involvement of the account holder. If a child
                        under 13 submits Personal Data to Signa X and we learn that the Personal Data is the information
                        of a child under 13, we will attempt to delete the information as soon as possible.</p>
                    <p class="content-sub-heading">1.4 Information Collected from Third-Party Companies - We may receive
                        Personal and/or Anonymous Data about you from companies that offer their products and/or
                        services for use in conjunction with our Service or whose products and/or services may be linked
                        from our Service. For example, third-party wallet providers provide us with your blockchain
                        address and certain other information you choose to share with those wallets providers. We may
                        add this to the data we have already collected from or about you through our Service.</p>
                    <p class="content-heading">2. USE OF PERSONAL INFORMATION</p>
                    <p class="content-sub-heading">Your personal information is used only for the specific purpose(s)
                        for which it was collected. Generally, we collect, use and disclose your personal data for the
                        following purposes :
                    </p>
                    <div class="content-sub-content">
                        <p>I. facilitate the creation of and secure your account;</p>
                        <p>II. identify you as a user in our system;</p>
                        <p>III. improve the administration of our Service and quality of experience when you interact
                            with our Service, including, but not limited to, by analyzing how you and other users find
                            and interact with the Service;</p>
                        <p>IV. provide customer support and respond to your requests and inquiries;</p>
                        <p>V. investigate and address conduct that may violate our Terms of Service;</p>
                        <p>VI. detect, prevent, and address fraud, violations of our terms or policies, and/or other
                            harmful or unlawful activity;</p>
                        <p>VII. send you a welcome email to verify ownership of the email address provided when your
                            account was created;</p>
                        <p>VIII. send you administrative notifications, such as security, support, and maintenance
                            advisories;</p>
                        <p>IX. send you newsletters, promotional materials, and other notices related to our Services
                            or third parties' goods and services;</p>
                        <p>X. respond to your inquiries related to employment opportunities or other requests;</p>
                        <p>XI. comply with applicable laws, cooperate with investigations by law enforcement or other
                            authorities of suspected violations of law, and/or to pursue or defend against legal threats
                            and/or claims; and</p>
                        <p>XII. act in any other way we may describe when you provide the Personal Data.</p>
                    </div>
                    <p class="content-footer-heading">In order for us to provide information about products and services
                        which may be of interest to you, we may analyse and rely on your overall interaction with us
                        (such as but not limited to your shopping behaviour on the website, your newsletter clicks /
                        opening results, your web surfing activities, the newsletter types you are subscribed to, and
                        your interactions with us).</p>
                    <p class="content-footer-heading">You may unsubscribe from receiving marketing materials at any time
                        by clicking on the “unsubscribe” link in the email you receive or updating your preferences to
                        indicate through which modes of communication and what kind of marketing communications you wish
                        to receive.</p>
                    <p class="content-heading">3. DISCLOSURE OF PERSONAL DATA</p>
                    <p class="content-sub-heading">Your personal information is kept private and confidential. We do not
                        sell your personal information to third parties. Subject to applicable law, your personal
                        information may be disclosed to the following third parties :
                    </p>
                    <p class="content-sub-heading">related corporations, parent company, subsidiaries and affiliates of
                        Signa X;
                    </p>
                    <p class="content-sub-heading">service providers who provide services to us, such as payment
                        processing, delivery, market research and/or technology service providers;
                    </p>
                    <p class="content-sub-heading">affiliates, partners, merchants and agents with whom we may jointly
                        run promotions, contests, surveys, privileges, marketing programmes and other initiatives;
                    </p>
                    <div class="content-sub-content">
                        <p>(d) our professional advisors, such as lawyers and auditors;</p>
                        <p>(e) anyone to whom we transfer or may transfer our rights and duties, such as in connection
                            with an acquisition or sale involving Signa X or its assets;</p>
                        <p>(f) relevant government regulators or law enforcement agencies to comply with laws, rules or
                            regulations imposed on us;</p>
                        <p>(g) banks, credit card companies and their respective service providers; and</p>
                        <p>(h) any other party to whom you authorise us to disclose your personal data.</p>
                    </div>
                    <p class="content-heading">4. PROTECTION OF PERSONAL INFORMATION</p>
                    <p class="content-sub-heading">We apply security measures such as encryption technology and
                        firewalls in order to prevent the unauthorised use, alteration, loss, hacking and disclosure of
                        our customers 'personal information. However, neither the computer security system nor the
                        Internet is entirely secure. You agree not to hold us responsible for any loss or damage
                        incurred as a result of any unauthorised access or use of the information you submit to or
                        receive from us through the Internet.
                    </p>
                    <p class="content-heading">5. COOKIES</p>
                    <p class="content-sub-heading">We use “cookies” to enhance user experience. “Cookies” are files that
                        a website or its service provider transfers to your mobile device or computer’s hard drive which
                        enable the website’s or service provider’s systems to recognise your device or browser and
                        capture and remember certain information. We use cookies to track information such as the number
                        of pageviews, the number of users, frequency of use, duration of use and common entry and exit
                        points into the website. If you do not agree to our use of cookies, you may disable cookies by
                        configuring your browser or device settings. However, this may restrict your use of certain
                        parts of the website.
                    </p>
                    <p class="content-heading">6. THIRD-PARTY SITES</p>
                    <p class="content-sub-heading">You may find links on the website which direct you to sites and
                        services of a third party that may require you to share your personal information. You
                        acknowledge that we do not control how these sites collect, use and share your personal
                        information and you further acknowledge that these third party sites fall outside the scope of
                        this Privacy Policy and are governed by their own respective privacy policies. We are not
                        responsible for the activity or content of any third party site. You are advised to read the
                        privacy policies of the respective third party sites you visit.
                    </p>
                    <p class="content-heading">7. ACCESS AND CORRECTION RIGHTS</p>
                    <p class="content-sub-heading">It is essential that the information you provide us with is true,
                        accurate, current and complete. We rely on you to ensure that your personal information is
                        accurate. You can view, access, edit, or delete your Personal Data for certain aspects of the
                        Service via your Settings page. Notwithstanding the above, we cannot edit or delete any
                        information that is stored on a blockchain, for example the Ethereum blockchain, as we do not
                        have custody or control over any blockchains. The information stored on the blockchain may
                        include purchases, sales, and transfers related to your blockchain address and NFTs held at that
                        address.You may notify us of any changes to your personal information held by us by sending us
                        an email at team@signa-x.io.
                    </p>
                    <p class="content-heading">8. WITHDRAWING CONSENT</p>
                    <p class="content-sub-heading">You may opt out of the collection, use and disclosure of your
                        personal information for any of the above stated purposes (including receiving marketing
                        materials) at any time by emailing us at team@signa-x.io. We will respond to your request as
                        soon as reasonably possible. Please note that for certain purposes, your withdrawal of consent
                        may prevent us from continuing to provide you with our products and/or services through this
                        website. Kindly also note that withdrawing your consent for any purposes stated above does not
                        affect our right to collect, use or disclose personal information where consent is not required
                        for such collection, usage or disclosure under applicable laws.
                    </p>
                    <p class="content-heading">9. CHANGES TO OUR PRIVACY POLICY</p>
                    <p class="content-sub-heading">We reserve the right to make changes/updates to this Privacy Policy
                        without giving you prior notice. You are responsible for keeping track of the changes made to
                        the Privacy Policy. Your continued use of the website shall constitute your agreement to be
                        bound by any such changes to this Privacy Policy.
                    </p>
                    <p class="content-heading">10. CONTACT INFORMATION</p>
                    <p class="content-sub-heading">If you have any questions relating to your personal information or
                        our Privacy Policy, please contact us at team@signa-x.io indicating the nature of your query in
                        the subject header.
                    </p>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "PrivacyPolicy",
    props: ["visible"],
    methods: {
        close() {
            this.$emit('close');
        }
    },
}
</script>
<style scoped>
.dialog {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 10px;

}

.title {
    font-size: 40px !important;
    text-align: left;
    font-family: 'Orbitron', sans-serif !important;
}

.header {
    background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Orbitron', sans-serif !important;
    font-weight: 800;
}

.content {
    font-family: 'Chakra Petch', sans-serif;
    color: white;
}

.sub-title {
    font-size: 16px;
    color: white;
    font-weight: 400;
    font-family: 'Chakra Petch', sans-serif;
    margin-top: -5px;
}

.content-desc {
    font-size: 14px;
    color: white;
    font-family: 'Chakra Petch', sans-serif;
}

.content-heading {
    font-size: 18px;
     background: linear-gradient(90.45deg, #00FFBF -2.93%, #1657DC 95.78%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: bold;
    font-family: 'Chakra Petch', sans-serif;
}

.content-sub-heading {
    font-size: 14px;
    color: white;
    font-weight: 400;
    font-family: 'Chakra Petch', sans-serif;
}

.content-sub-content {
    margin-left: 5%;
}

.content-sub-heading {
    font-size: 14px;
    color: white;
    font-weight: 400;
}
</style>