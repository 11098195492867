<template>
    <div>
        <v-dialog v-model="visible" hide-overlay persistent width="300">
            <v-card color="#090d2e" dark>
                <v-card-text>
                    Submitting..
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "Loader",
    props: ['visible']
}
</script>