import { addDoc, websiteRef, waitListRef } from '../handler/firebase';

class RegisterServices {

    async create(data) {
        try {
            const newJson = {
                ...data,
                createdAt: Date.now(),
            }
           const registeredData =  await addDoc(websiteRef, newJson);
           return registeredData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async waitlist(data) {
        try {
            const date = new Date();
            const newJson = {
                ...data,
                createdAt: date,
            }
           const registeredData =  await addDoc(waitListRef, newJson);
           return registeredData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}
export const registerObj = new RegisterServices();